/**
 * @name: 交易管理-订单管理接口文件
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 交易管理-订单管理接口文件
 * @update: 2023-12-07 16:39
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {ISaleOrderRes, ISaleOrderParam} from "@/apis/sale/order/types";

/**
 * 分页查询
 * @param params
 */
export const orderQueryByPageApi = (params: ISaleOrderParam) => get<ISaleOrderRes>("/card/order/queryByPage", params)
/**
 * 导出
 * @param params
 */
export const orderListExportApi = (params: ISaleOrderParam) => get("/card/order/orderListExport", params, "blob")
/**
 * 订单详情
 * @param id
 */
export const orderDetailApi = (id: string) => get("/card/order/detail/" + id)
/**
 * 导出商品明细
 * @param params
 */
export const orderProductListExportApi = (params: ISaleOrderParam) => get("/card/order/orderProductListExport", params, "blob")
/**
 * 订单状态详情
 * @param id
 */
export const orderQueryOrderStatusDetailApi = (id: string) => get("/card/order/queryOrderStatusDetail", {id})
/**
 * 查询发货明细
 * @param orderDeliveryId
 */
export const orderQuerySendGoodsDetailApi = (orderDeliveryId: string) => get("/card/order/querySendGoodsDetail", {orderDeliveryId})
/**
 * 物流信息
 * @param orderDeliveryId
 */
export const orderQueryLogisticsDetailApi = (orderDeliveryId: string) => get("/card/order/queryLogisticsDetail", {orderDeliveryId})
/**
 * 快递公司
 */
export const expressCompanyCodeQueryByListApi = (companyName: string) => get("/card/expressCompanyCode/queryByList", {companyName})
/**
 * 补发货商品列表
 * @param id 订单id
 */
export const orderQuerySupplementSendGoodsInfoApi = (id: string) => get("/card/order/querySupplementSendGoodsInfo", {id})
/**
 * 补发货
 * @param data
 */
export const orderSupplementSendGoodsApi = (data: any) => postJ("/card/order/supplementSendGoods", data)
