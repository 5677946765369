
/**
 * @name: 交易管理-订单管理
 * @author: itmobai
 * @date: 2023-12-07 16:39
 * @description： 交易管理-订单管理
 * @update: 2023-12-07 16:39
 */
import { Vue, Component } from "vue-property-decorator"
import {
  orderQueryByPageApi,
  orderListExportApi,
  orderDetailApi,
  orderProductListExportApi,
  orderQueryOrderStatusDetailApi,
  orderQuerySendGoodsDetailApi,
  orderQueryLogisticsDetailApi,
  expressCompanyCodeQueryByListApi, orderQuerySupplementSendGoodsInfoApi, orderSupplementSendGoodsApi
} from "@/apis/sale/order"
import { ISaleOrder, ISaleOrderParam } from "@/apis/sale/order/types"
import type { ICrudOption } from "@/types/m-ui-crud"
import {deepCopy, exportFile, getlogisticStatus} from "@/utils/common";

@Component({})
export default class saleOrder extends Vue {
  getlogisticStatus = getlogisticStatus
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: ISaleOrder[] = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISaleOrderParam = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<ISaleOrder> = {}
  // crud配置信息
  crudOption: ICrudOption = {
    dialogWidth: '1200px',
    labelWidth: '120px',
    viewTitle: '订单详情',
    column: [
      {
        label: "订单编号",
        prop: "orderSn",
        search: true,
        align: "left",
        slot: true,
        width: 180,
        span: 6
      },
      {
        label: "用户",
        prop: "userKeywords",
        search: true,
        hide: true,
        viewHide: true,
        placeholder: "用户名/手机号"
      },
      {
        label: "用户名",
        prop: "userName",
        align: "center",
        width: 150,
        overHidden: true,
        span: 6
      },
      {
        label: "手机号",
        prop: "userPhone",
        align: "center",
        width: 150,
        span: 6
      },
      {
        label: "订单金额",
        prop: "orderPrice",
        align: "center",
        width: 100,
        span: 6
      },
      {
        label: "商品金额",
        prop: "productPrice",
        align: "center",
        width: 100,
        span: 6
      },
      {
        label: "礼品卡抵扣金额",
        prop: "couponPrice",
        align: "center",
        width: 120,
        span: 6
      },
      {
        label: "运费",
        prop: "freightPrice",
        align: "center",
        width: 100,
        span: 6
      },
      {
        label: "实付金额",
        prop: "payPrice",
        align: "center",
        width: 100,
        span: 6
      },
      {
        label: "订单状态",
        prop: "orderStauts",
        align: "center",
        width: 100,
        search: true,
        type: "select",
        dicData: [
          {
            label: "待支付",
            value: 1
          },
          {
            label: "待发货",
            value: 2
          },
          {
            label: "待收货",
            value: 3
          },
          {
            label: "已完成",
            value: 4
          },
          {
            label: "已取消",
            value: 5
          },
        ],
        slot: true,
        span: 6,
        viewHide: true
      },
      {
        label: "订单状态",
        prop: "orderStauts",
        hide: true,
        span: 6,
        viewSlot: true
      },
      {
        label: "下单时间",
        prop: "orderTime",
        search: true,
        align: "center",
        type: "daterange",
        width: 150,
        span: 6
      },
      {
        label: "付款时间",
        prop: "payTime",
        hide: true,
        span: 6
      },
      {
        label: "发货时间",
        prop: "deliveryTime",
        hide: true,
        span: 6,
      },
      {
        label: "完成时间",
        prop: "finishTime",
        search: true,
        align: "center",
        type: "daterange",
        width: 150,
        span: 6
      },
      {
        label: "商品数量",
        prop: "productNumber",
        hide: true,
        span: 6
      },
      {
        label: "退款金额",
        prop: "refundPrice",
        hide: true,
        span: 12,
        viewSlot: true
      },
      {
        label: "收货地址",
        prop: "detailAddress",
        hide: true,
        span: 24,
        viewSlot: true
      },
      {
        label: "备注",
        prop: "orderRemarks",
        hide: true,
        span: 12,
      },
      {
        label: "订单取消原因",
        prop: "cancelRemarks",
        hide: true,
        span: 12,
      },
    ]
  }

  // 订单金额合计
  orderPriceTotal = 0
  // 礼品卡金额合计
  couponPriceTotal = 0
  // 实付金额合计
  payPriceTotal = 0
  // 运费金额合计
  freightPriceTotal = 0

  // 订单状态详情
  orderStatusDetail = false
  // 订单状态详情数据
  orderStatusDetailObj: any = {}

  // 发货模式(1 发货 2 补发货)
  sendType = 1
  // 发货明细
  sendDetail = false
  // 发货明细数据
  sendDetailArr: any = []

  // 物流详情弹窗
  logisticsDialog = false
  // 物流信息
  logisticsInfo: any = {}

  // 选择的订单
  selectOrderRow: Partial<ISaleOrder> = {}

  // 补发货弹窗
  retrySendProductDialog = false
  // 补发货数据
  retrySendProductObj: any = {}

  retrySendProductForm: any = {
    logisticCode: '',
    shipperCode: ''
  }

  /**
   * 快递公司列表
   */
  companyList: any = []

  // 搜索loading
  searchLoading = false

  checkA9 (rule: any, value: any, callback: Function) {
    if (!/^[A-Za-z0-9]+$/.test(value)) {
      return callback(new Error('只支持字母、数字'))
    }
    callback()
  }

  getCompanyList (keyword?: string) {
    return new Promise(resolve => {
      expressCompanyCodeQueryByListApi(keyword || '').then(e => {
        this.companyList = e.splice(0, 100);
        resolve(null)
      })
    })
  }

  getList () {
    this.tableLoading = true
    const query: ISaleOrderParam = deepCopy(this.queryParam)

    if (query.orderTime && query.orderTime.length) {
      query.orderTimeStart = query.orderTime[0]
      query.orderTimeEnd = query.orderTime[1]
    } else {
      query.orderTimeStart = ''
      query.orderTimeEnd = ''
    }
    delete query.orderTime

    if (query.finishTime && query.finishTime.length) {
      query.finishTimeStart = query.finishTime[0]
      query.finishTimeEnd = query.finishTime[1]
    } else {
      query.finishTimeStart = ''
      query.finishTimeEnd = ''
    }
    delete query.finishTime

    orderQueryByPageApi(query).then(e => {
      if (e) {
        this.tableData = e.commonPage.list
        this.tableTotal = e.commonPage.total

        this.orderPriceTotal = e.orderPriceTotal
        this.payPriceTotal = e.payPriceTotal
        this.couponPriceTotal = e.couponPriceTotal
        this.freightPriceTotal = e.freightPriceTotal

        this.tableLoading = false
      }
    })
  }

  getOrderStatus (status: number) {
    switch (status) {
      case 1:
        return "待支付"
      case 2:
      case 3:
        return "待发货"
      case 4:
        return "待收货"
      case 5:
        return "已完成"
      case 6:
      case 7:
        return "已取消"
    }
  }

  exportExcel () {
    const query: ISaleOrderParam = deepCopy(this.queryParam)

    if (query.orderTime && query.orderTime.length) {
      query.orderTimeStart = query.orderTime[0]
      query.orderTimeEnd = query.orderTime[1]
    } else {
      query.orderTimeStart = ''
      query.orderTimeEnd = ''
    }
    delete query.orderTime

    if (query.finishTime && query.finishTime.length) {
      query.finishTimeStart = query.finishTime[0]
      query.finishTimeEnd = query.finishTime[1]
    } else {
      query.finishTimeStart = ''
      query.finishTimeEnd = ''
    }
    delete query.finishTime

    orderListExportApi(query).then(e => {
      exportFile(e, "订单.xlsx")
    })
  }

  exportDetailExcel () {
    const query: ISaleOrderParam = deepCopy(this.queryParam)

    if (query.orderTime && query.orderTime.length) {
      query.orderTimeStart = query.orderTime[0]
      query.orderTimeEnd = query.orderTime[1]
    } else {
      query.orderTimeStart = ''
      query.orderTimeEnd = ''
    }
    delete query.orderTime

    if (query.finishTime && query.finishTime.length) {
      query.finishTimeStart = query.finishTime[0]
      query.finishTimeEnd = query.finishTime[1]
    } else {
      query.finishTimeStart = ''
      query.finishTimeEnd = ''
    }
    delete query.finishTime

    orderProductListExportApi(query).then(e => {
      exportFile(e, "商品销售明细.xlsx")
    })
  }

  /**
   * 打开订单详情
   * @param row
   * @param index
   */
  openDetail (row: ISaleOrder, index: number) {
    orderDetailApi(row.orderId).then(e => {
      if (e) {
        // @ts-ignore
        this.$refs.crudRef.rowView(e)
      }
    })
  }

  /**
   * 打开订单状态详情
   * @param row 订单
   */
  openOrderStatusDetail (row: ISaleOrder) {
    orderQueryOrderStatusDetailApi(row.orderId).then(e => {
      this.orderStatusDetailObj = e;
      this.selectOrderRow = row;
      this.orderStatusDetail = true
    })
  }

  openSendDetail (orderDeliveryId: string, t: number) {
    this.sendType = t;
    orderQuerySendGoodsDetailApi(orderDeliveryId).then(e => {
      this.sendDetailArr = e;
      this.sendDetail = true
    })
  }

  /**
   * 打开物流信息
   * @param row
   */
  openLogisticsDialog (row: any) {
    this.logisticsInfo.logisticName = row.logisticName
    this.logisticsInfo.logisticCode = row.logisticCode
    orderQueryLogisticsDetailApi(row.orderDeliveryId).then(e => {
      this.logisticsInfo.list = e || []
      this.logisticsDialog = true
    })
  }


  openRetrySendProductDialog (id: string) {
    orderQuerySupplementSendGoodsInfoApi(id).then(e => {
      this.retrySendProductObj = e;
      this.retrySendProductDialog = true
    })
  }

  async retrySendProductEnter (done: Function, loading: Function) {
    if (this.retrySendProductObj && this.retrySendProductObj.orderProductVoList) {
      for (let i = 0; i < this.retrySendProductObj.orderProductVoList.length; i++) {
        const item = this.retrySendProductObj.orderProductVoList[i]
        if (item.retryQty && parseFloat(item.retryQty || 0) != 0) {
          // 正则整数校验retryQty
          const reg = /^[1-9]\d*$/
          if (!reg.test(item.retryQty)) {
            loading()
            return this.$message.error("请输入正整数的补发数量")
          }
          if (parseInt(item.retryQty) > parseInt(item.payQty)) {
            loading()
            return this.$message.error("补发数量不能超出商品数量")
          }
        }
      }
      const arr = this.retrySendProductObj.orderProductVoList.filter((item: any) => item.retryQty && parseFloat(item.retryQty || 0) != 0)
      if (!arr || !arr.length) {
        loading()
        return this.$message.error("未填写补发货数量!")
      }
      // @ts-ignore
      this.$refs.retrySendProductRef.validate(valid => {
        if (valid) {
          // @ts-ignore
          orderSupplementSendGoodsApi({
            orderId: this.retrySendProductObj.orderId,
            logisticCode: this.retrySendProductForm.logisticCode,
            shipperCode: this.retrySendProductForm.shipperCode,
            orderDeliveryDtlDtoList: arr.map((item: any) => {
              return {
                orderProductId: item.orderProductId,
                deliveryQty: item.retryQty
              }
            })
          }).then(e => {
            if (e) {
              this.$message.success("操作成功!")
              this.getList()
              done()
            }
          }).finally(() => {
            loading()
          })
        } else {
          loading()
        }
      })
    } else {
      loading()
      this.$message.error("该订单暂不允许补发货!")
    }
  }

  retrySendProductClose () {
    this.retrySendProductForm = {
      logisticCode: '',
      shipperCode: ''
    }
  }

  async remoteMethod (query: string) {
    this.searchLoading = true
    await this.getCompanyList(query)
    this.searchLoading = false
  }

  created () {
    this.getCompanyList()
    this.getList()
  }
}
